<template>
	<div @keydown.enter="resetPassword()">
		<h1>Resetovať heslo</h1>

		<form class="pt-3">
			<div class="row">
				<div class="form-group col-12 d-none">
					<label for="verificationCode">Verifikačný kód</label>
					<input v-model="credentials.code" type="email" class="form-control" id="verificationCode" placeholder="Zadajte verifikačný kód ktorý bol odoslaný na Váš email"  autocomplete="none">
				</div>

				<div class="form-group col-12">
					<label for="emailAddress">Emailová adresa</label>
					<input v-model="credentials.email" type="email" class="form-control" id="emailAddress" placeholder="Zadajte email"  autocomplete="email">
				</div>

				<div class="form-group col-12">
					<label for="newPassword">Nové heslo</label>
					<input v-model="credentials.password" type="password" class="form-control" id="newPassword" placeholder="Zadajte nové heslo" autocomplete="new-password">
				</div>

				<div class="form-group col-12">
					<label for="passwordConfirmation">Potvrdenie hesla</label>
					<input v-model="credentials.password_confirmation" type="password" class="form-control" id="passwordConfirmation" placeholder="Potvrďte nové heslo" autocomplete="new-password">
				</div>
			</div>
			<b-button variant="primary" @click.prevent="resetPassword()" :disabled="$wToast.isLoading()">Zmeniť heslo</b-button>
		</form>

	</div>
</template>

<script>

export default {
	props: ['navigateToAfterPasswordReset'],

	data() {
		return {
			credentials: {
				code: '',
				email: '',
				password: '',
				password_confirmation: ''
			}
		}
	},

	created() {
		this.credentials.email = new URLSearchParams(window.location.search.substring(1)).get('email')
		this.credentials.code = new URLSearchParams(window.location.search.substring(1)).get('code')
	},
	
	methods: {
		async resetPassword() {
			this.$wToast.clear_loading()

			try {
				await this.$store.dispatch('wAuth/resetPassword', this.credentials)
				this.$wToast.stopLoading()
				this.$router.push('/prihlasit-sa')
			} catch (error) {
				this.$wToast.error(error)
			}
		}
	}
}
</script>
<style lang="scss" scoped>
        h1 {
            margin-bottom: 5px !important
        }

        h5 {
            margin-bottom: 30px
        }
</style>
